<template>
  <v-row>
    <h1>Payment</h1>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }
  },
  mounted(){
    document.title = 'Payment'
    this.$store.state.pageTitle = document.title
  },
}
</script>
